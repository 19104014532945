<template>
	<div>
		<svg :width="props.size" :height="props.size">
			<ellipse
				:cx="props.size / 2"
				:cy="props.size / 2"
				:rx="props.size / 2 - strokeWidth / 2"
				:ry="props.size / 2 - strokeWidth / 2"
				fill="transparent"
				stroke="rgb(240,240,240)"
				:stroke-width="strokeWidth" />
			<g style="transform: rotate(-90deg); transform-origin: center">
				<ellipse
					class="goal"
					:cx="props.size / 2"
					:cy="props.size / 2"
					:rx="props.size / 2 - strokeWidth / 2"
					:ry="props.size / 2 - strokeWidth / 2"
					fill="transparent"
					stroke="rgba(0,0,0,0.1)"
					:stroke-width="strokeWidth"
					:stroke-dasharray="goalDashArray" />

				<ellipse
					class="score"
					:cx="props.size / 2"
					:cy="props.size / 2"
					:rx="props.size / 2 - strokeWidth / 2"
					:ry="props.size / 2 - strokeWidth / 2"
					fill="transparent"
					:stroke="`rgb(${color.join(',')})`"
					:stroke-width="strokeWidth"
					:stroke-dasharray="dashArray" />
			</g>

			<text :x="props.size / 2" :y="props.size / 2" class="numeric-score" :fill="`rgb(${color.join(',')})`">
				{{ modelValue }}
			</text>
		</svg>
	</div>
</template>

<script setup>
const props = defineProps({
	size: {type: Number, required: true},
	max: {type: Number, required: true},
	min: {type: Number, required: true},
	modelValue: {type: Number, required: true},
	goalValue: {type: Number, required: true},
	minColor: {
		type: Array,
		required: false,
		default() {
			return [236, 0, 0];
		}
	},
	belowColor: {
		type: Array,
		required: false,
		default() {
			return [255, 204, 0];
		}
	},
	aboveColor: {
		type: Array,
		required: false,
		default() {
			return [153, 204, 0];
		}
	},
	maxColor: {
		type: Array,
		required: false,
		default() {
			return [0, 255, 0];
		}
	}
});

const strokeWidth = ref(15);

function lerp(a, b, x) {
	return a * x + b * (1 - x);
}

const color = computed(() => {
	if (props.modelValue < props.goalValue) {
		const v = Math.max(0, Math.min(1, (props.modelValue - props.min) / (props.goalValue - props.min)));
		return [
			lerp(props.minColor[0], props.belowColor[0], 1 - v),
			lerp(props.minColor[1], props.belowColor[1], 1 - v),
			lerp(props.minColor[2], props.belowColor[2], 1 - v)
		];
	} else {
		const v = Math.max(0, Math.min(1, (props.modelValue - props.goalValue) / (props.max - props.goalValue)));
		return [
			lerp(props.aboveColor[0], props.maxColor[0], 1 - v),
			lerp(props.aboveColor[1], props.maxColor[1], 1 - v),
			lerp(props.aboveColor[2], props.maxColor[2], 1 - v)
		];
	}
});

const totalLength = computed(() => {
	return Math.PI * (props.size - strokeWidth.value);
});

const dashArray = computed(() => {
	const v = (props.modelValue - props.min) / (props.max - props.min);
	return [v * totalLength.value, (1 - v) * totalLength.value].join(" ");
});

const goalDashArray = computed(() => {
	const v = (props.goalValue - props.min) / (props.max - props.min);
	return [v * totalLength.value, (1 - v) * totalLength.value].join(" ");
});
</script>

<style scoped>
.numeric-score {
	text-anchor: middle;
	dominant-baseline: middle;
	font-family: verdana;
	font-weight: bold;
	font-size: 14px;
}

/* ellipse.goal {
	animation-name: pulse;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
	animation-direction: alternate-reverse;
	animation-timing-function: ease-in-out;
} */

@keyframes pulse {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}
</style>
